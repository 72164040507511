// Summary And Greeting Section

import emoji from "react-easy-emoji";

const illustration = {
  animated: true // set to false to use static SVG
};

const greeting = {
  username: "Alan Beaucheron",
  title: "Bonjour, je suis Alan",
  subTitle: emoji(
    "Un développeur front-end passionné par l'informatique et ses technologies🚀  travaillant avec JavaScript / Reactjs / PHP."
  ),
  resumeLink:
    "",
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/alan62",
  linkedin: "https://www.linkedin.com/in/",
  gmail: "beaucheron.alan@free.fr",
  gitlab: "https://gitlab.com/",
  facebook: "https://www.facebook.com/",
  medium: "https://medium.com/@Abeaucheron",
  stackoverflow: "https://stackoverflow.com/users/11740364/xmo",
  instagram: 'https://www.instagram.com/xmoo62/',
  twitter: 'https://twitter.com/xmo62',
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "Ce que je fais",
  subTitle: "Développeur web qui veut apprendre encore et encore",
  skills: [
    emoji(
      "⚡ Développeur front-end réalisant des applications mobiles responsives avec REACTJS ou des CMS tel que wordpress ou prestashop"
    ),
    emoji(
      "⚡ Utilisation de Firebase / MongoDB "
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql-database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "PHP",
      fontAwesomeClassname: "fab fa-php"
    },
    {
      skillName: "WordPress",
      fontAwesomeClassname: "fab fa-wordpress"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "OpenClassRooms",
      logo: require("./assets/images/OPCLogo.png"),
      subHeader: "BTS développeur web junior",
      duration: "Septembre 2018 - Juillet 2020",
      desc: "Apprentissage HTML / CSS / PHP / JQUERY / API / AJAX et bien plus encore",
      descBullets: [
        "Assembler les pages en HTML5 et CSS3 à partir de maquettes graphiques.",
        "Intégrer des contenus textes, images, sons, vidéos dans le code HTML5.",
        "Respecter les normes d’accessibilité et de référencement du W3C.",
        "Assurer la compatibilité avec les différents navigateurs du marché.",
        "Mettre en place une communication entre client et serveur avec JavaScript et PHP.",
        "Organiser et gérer la base de données."
      ]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend / Design", //Insert stack or technology you have experience in
      progressPercentage: "80%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "50%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: true // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: false, //Set it to true to show workExperiences Section
  experience: [
    {
      // role: "",
      // company: "",
      // companylogo: require(""),
      // date: "",
      // desc:
      // "",
       //descBullets: [
       // "",
       // "",
       // "",
       // ""
     // ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  githubConvertedToken: process.env.REACT_APP_GITHUB_TOKEN,
  githubUserName: "xmo62", // Change to your github username to view your profile in Contact Section.
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Mes projets",
  // subtitle: "Quelques projets que j'ai accomplis",
  projects: [
    {
      image: require("./assets/images/p1.png"),
      projectName: "Intégrez la maquette du site d'une agence web",
      projectDesc: "Réalisé en HTML et CSS (sans framework tel que Bootstrap)",
      footerLink: [
        {
          name: "Voir le projet",
          url: "https://alanbeaucheron.ovh/projet1/"
        }
      ]
    },
    {
      image: require("./assets/images/CMS.png"),
      projectName: "Création d'un site touristique",
      projectDesc: "Créer un site touristique sous wordpress",
      footerLink: [
        {
          name: "Voir le projet",
          url: "https://projet2.alanbeaucheron.ovh/"
        }
      ]
    },
    {
      image: require("./assets/images/p3.png"),
      projectName: "Création d'un site de locations de vélos avec javascript en orienté objet",
      projectDesc: "Concevez une carte interactif de locations de vélo à partir d'un mockup avec API / Javascript / html / CSS, signature canvas",
      footerLink: [
        {
          name: "Voir le projet",
          url: "https://alanbeaucheron.ovh/projet3/"
        }
      ]
    },{
      image: require("./assets/images/p4.png"),
      projectName: "Création d'un blog avec structure MVC",
      projectDesc: "Créer un blog avec PHP,HTML,CSS,Javascript avec un panel admin afin d'ajouter directement ses chapitres en ligne et pouvoir le modifier, supprimer via un CRUD",
      footerLink: [
        {
          name: "Voir le projet",
          url: "https://alanbeaucheron.ovh/projet4/"
        }
      ]
    },
    {
      image: require("./assets/images/artex.png"),
      projectName: "Création d'un site vitrine",
      projectDesc: "Création d'un site vitrine avec REACTJS, css",
      footerLink: [
        {
          name: "Voir le projet",
          url: "https://artexalpha.fr"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "",
      subtitle:
        "",
      image: require("./assets/images/codeInLogo.webp"),
      footerLink: [
        {
          name: "",
          url:
            ""
        },
        {
          name: "",
          url:
            ""
        },
        {
          name: "",
          url:
            ""
        }
      ]
    },
    {
      title: "",
      subtitle:
        "",
      // image: require(""),
      footerLink: [
        {
          name: "",
          url:
            ""
        }
      ]
    },

    {
      title: "",
      subtitle: "",
      image: require("./assets/images/pwaLogo.webp"),
      footerLink: [
        {name: "", url: ""},
        {
          name: "",
          url: ""
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "Jamais trop tard pour apprendre",

  blogs: [
    {
      url:
        "",
      title: "",
      description:
        ""
    },
    {
      url: "",
      title: "",
      description:
        ""
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    ""
  ),

  talks: [
    {
      title: "",
      subtitle: "",
      slides_url: "",
      event_url: ""
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "J'aime parler de technologies et apprendre, l'apprentissage n'est jamais fini ",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    ""
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contactez moi ☎️"),
  subtitle:
    "Envie de discuter d'un éventuel projet ou juste de dire bonjour? c'est par ici :).",
  number: "0783716123",
  cv: "https://alanbeaucheron.ovh/cv/",
  email_address: "beaucheron.alan@free.fr"
};

// Twitter Section

const twitterDetails = {
  userName: "xmo62", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
